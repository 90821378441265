import { useState, useEffect, useRef } from "react";
import { AuthTokenService } from "@/src/core/app/data/services/auth_token_service";
import NotificationPopup from "./notification_popup";

interface Notification {
  id: number;
  message: string;
  timestamp: string;
  is_read: boolean;
}

const WebSocketNotifications = () => {
  const [notification, setNotification] = useState<Notification | null>(null);
  const socketRef = useRef<WebSocket | null>(null);

  useEffect(() => {
    const initializeWebSocket = async () => {
      const authTokenService = new AuthTokenService();
      const token = authTokenService.token;

      if (!token) {
        console.error("No token found for WebSocket authentication");
        return;
      }

      const socketUrl = `${process.env.REACT_APP_WSS_URL}ws/notifications/?token=${token}`;
      const socket = new WebSocket(socketUrl);

      socketRef.current = socket;

      socket.onmessage = (event) => {
        try {
          const data: Notification = JSON.parse(event.data);
          setNotification(data);
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      return () => {
        socket.close();
      };
    };

    initializeWebSocket();
  }, []);

  const markAsRead = async (id: number) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      const message = JSON.stringify({
        id: id,
        action: "mark_as_read"
      });
      await socketRef.current.send(message);
    } else {
      console.error("WebSocket is not connected");
    }
  };

  return (
    <>
      {notification && (
        <NotificationPopup id={notification.id} message={notification.message} onClose={() => setNotification(null)} onMarkAsRead={markAsRead} />
      )}
    </>
  );
};

export default WebSocketNotifications;
